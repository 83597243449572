<div class="gap-4" [ngClass]="!!width ? 'flex' : 'grid grid-cols-2'">
  <div>
    <ptg-select
      [listData]="_renderOptions"
      [controlField]="control"
      [isSetMaxWidthSelect]="true"
      [isOptionObj]="true"
      class="w-full"
      [placeholder]="placeholder"
      (blur)="handleBlur()"
      [width]="width"
    ></ptg-select>
  </div>
  <div class="flex gap-4">
    <button
      mat-raised-button
      type="button"
      class="add-btn"
      type="button"
      (click)="addValue()"
      [disabled]="disabled"
      [class.disabled]="disabled"
    >
      <mat-icon>add</mat-icon>
    </button>
    <div class="chip-item-container">
      <span class="chip" *ngFor="let item of selectedOptions; let i = index">
        <span>{{ item.displayValue }}</span>
        <button mat-button type="button" class="x-btn" (click)="removeValue(item)">✕</button>
      </span>
    </div>
  </div>
</div>
