export interface EditMunicipalityServiceHistoryRequest {
  id?: string;
  memberId: string;
  beginDate: string;
  endDate?: string | null;
  municipalityId: string;
}

export interface MunicipalityNameList {
  employers: Municipality[];
}

export interface Municipality {
  value: string;
  description: string;
  disabled: boolean;
}

export interface ValidateTerminatedMemberBody {
	serviceHistoryEndDate: string;
	municipalityId: string;
	memberId: string;
}

export interface ValidateTerminatedMemberResponse {
	isShowPopup: boolean;
	annualCertId: string;
	year: number;
  name: string;
  nextCashJournalEntry: number;
  credit: number;
  municipalityName: string;
}

export interface CreateTransactionBody {
	municipalityId: string;
	year: number;
	memberId: string;
	annualCertId: string;
	cashJournalEntry: string;
}

export interface EditMetRequirementBody {
  municipalityId: string;
  year: number;
  memberId: string;
  metRequirement: boolean;
  validateToShowPopup: boolean;
  cashJournalEntry?: any;
  isCreateTransaction: boolean;
}

export interface EditMetRequirementResponse {
  isShowConfirmPopup: boolean;
  nextCashJournalEntry: number;
  credit: number;
  name: string;
}

export interface ValidateServiceHistoryRecordTerminatedMemberRequest {
  oldBeginDate: string,
  newBeginDate: string,
  oldEndDate: string | null,
  newEndDate: string | null,
  serviceHistoryId: string;
  memberId: string,
  municipalityId: string,
}

export interface ValidateServiceHistoryRecordTerminatedMemberResponse {
  gapYears?: string,
  isPortalValidServiceBeginDate?: boolean,
  memberId?: string,
  nextCashJournalEntry?: number,
  totalCreditAmount?: number,
}

export interface CreateValidateServiceHistoryRecordBeginDateTransactionRequest {
  createDate: string,
  gapYears: string,
  totalCreditAmount: number,
  municipalityId: string,
  memberId: string,
  cashJournalEntry: string,
}

export interface CreateValidateServiceHistoryRecordBeginDateTransactionResponse {

}

export interface EmployerCheckServiceBeginDate {
  memberId: string;
  serviceBeginDate: string;
}

export interface ValidateRemoveServiceHistoryResponse {
  isShowRemovePopup: boolean;
  creditAmount: number;
  greatestCashJournal: number;
  gapYears: number[];
  participantName: string;
}

export interface RemoveServiceHistoryCJRequest {
  isShowRemovePopup: boolean;
  cashJournalEntry: string;
  gapYears: number[];
  id: string;
  memberId: string;
  municipalityId: string;
  creditAmount: number;
  postedDate: string;
  localDate: string;
}

export interface RemoveServiceHistoryRequest {
  memberId: string;
  muniId: string;
  isShowRemovePopup: boolean;
  id: string;
}

export interface AddDisabilityPaymentRequestBody {
  memberId: string;
  disabilityPayments: DisabilityPayment[]
}

export interface GetDisabilityPaymentsResponse {
  total: number;
  disabilityPayments: DisabilityPayment[]
}

export interface DisabilityPayment {
  year: number;
  municipalityId: string;
  disabilityFee: number;
  municipalityName?: string;
}

